<template>
    <div class="container">
      <div class="work__title">
        <div class="work__title-main">
          <h1>{{$t('work_approach_page.work_approach_section.title')}}</h1>
        </div>
        <div class="work__title-text">
          {{$t('work_approach_page.work_approach_section.text')}}
        </div>
      </div>
    </div>


    <section class="delivery">
      <picture>
        <source
            srcset="../assets/workapp-bg.webp"
            type="image/webp"
        >
        <img
            class="delivery__bg"
            src="../assets/workapp-bg.png"
            alt=""
            loading="lazy"
        />
      </picture>

      <div class="delivery--bg">
        <div class="container">
          <div>
            <h2 class="delivery--title">{{$t('work_approach_page.delivery_process_section.title')}}</h2>
          </div>
          <div class="delivery--list">
            <div class="delivery--blur">
              <picture>
                <source srcset="../assets/workapp-bg-blur.webp" type="image/webp">
                <img
                    src="../assets/workapp-bg-blur.png"
                    alt=""
                    loading="lazy"
                />
              </picture>
            </div>
              <div class="delivery--list-images">
                <img src="../assets/approach_icon_1.svg" alt="">
                <img src="../assets/approach_icon_2.svg" alt="">
                <img src="../assets/approach_icon_3.svg" alt="">
              </div>

              <div class="delivery--list-main">
                <div class="delivery--item">
                  <div>
                    <h3 class="delivery--item-title">{{$t('work_approach_page.delivery_process_section.delivery_process_list[0].title')}}</h3>
                  </div>
                  <div class="delivery--item-text">{{$t('work_approach_page.delivery_process_section.delivery_process_list[0].text')}}
                  </div>
                </div>
                <div class="delivery--item">
                  <div>
                    <h3 class="delivery--item-title">{{$t('work_approach_page.delivery_process_section.delivery_process_list[1].title')}}</h3>
                  </div>
                  <div class="delivery--item-text">{{$t('work_approach_page.delivery_process_section.delivery_process_list[1].text')}}
                  </div>
                </div>
                <div class="delivery--item">
                  <div class="delivery--item-title">
                    <h3 class="delivery--item-title">{{$t('work_approach_page.delivery_process_section.delivery_process_list[2].title')}}</h3>
                  </div>
                  <div class="delivery--item-text">{{$t('work_approach_page.delivery_process_section.delivery_process_list[2].text')}}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
    </section>



    <div class="stack">
      <div class="container">
        <div>
          <h2 class="stack--title"> {{$t('work_approach_page.tech_stack_section.title')}}</h2>
        </div>
        <div class="stack--inner">

          <div class="stack--inner-item top">
            <div class="stack--list align-self-start">

              <div>
                <h3 class="stack--list-title">{{$t('work_approach_page.tech_stack_section.tech_stack_list[0].title')}}</h3>
              </div>

              <div class="stack--item-description">{{$t('work_approach_page.tech_stack_section.tech_stack_list[0].text')}}
              </div>


              <div class="stack--list-items first-item">
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/js_icon.svg" alt="">
                  </div>
                  <div class="stack--list-item-name">JavaScript</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/ts_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">TypeScript</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/react_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">React</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/vue_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Vue Js</div>
                </div>
              </div>


            </div>

            <div class="stack--list pd-lt right-top">
              <div>
                <h3 class="stack--list-title">{{$t('work_approach_page.tech_stack_section.tech_stack_list[1].title')}}</h3>
              </div>

              <div class="stack--item-description">{{$t('work_approach_page.tech_stack_section.tech_stack_list[1].text')}}
              </div>

              <div class="stack--list-items">
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/node_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Node.js</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/ruby_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Ruby</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/ruby_on_rails_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Ruby on Rails</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/sinatra_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Sinatra</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/jam_stack_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">JAMStack</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/symfony_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">PHP/Symfony</div>
                </div>

                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/express_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">NodeJS/Express</div>
                </div>
              </div>



            </div>
          </div>

          <div class="stack--inner-item bottom left-bottom">
            <div class="stack--list pd-rt p-t-0 align-self-start">
              <div>
                <h3 class="stack--list-title">{{$t('work_approach_page.tech_stack_section.tech_stack_list[2].title')}}</h3>
              </div>

              <div class="stack--item-description">{{$t('work_approach_page.tech_stack_section.tech_stack_list[2].text')}}
              </div>


              <div class="stack--list-items">
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/maria_db_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">MariaDB</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/mongo_db_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">MongoDB</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/firebase_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Firebase</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/big_query_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">BigQuery</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/my_sql_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">MySQL</div>
                </div>
              </div>

            </div>

            <div class="stack--list pd-lt right-bottom">
              <div>
                <h3 class="stack--list-title">{{$t('work_approach_page.tech_stack_section.tech_stack_list[3].title')}}</h3>
              </div>

              <div class="stack--item-description">{{$t('work_approach_page.tech_stack_section.tech_stack_list[3].text')}}
              </div>


              <div class="stack--list-items last-item">
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/github_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">CI/CD github</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/aws_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">AWS</div>

                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/docker_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Docker</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/gitlab_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">CI/CD gitlab</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/gsp_icon.svg" alt="" loading="lazy">
                  </div>

                  <div class="stack--list-item-name">GCP</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/docker_compose_icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Docker-compose</div>

                </div>
              </div>


            </div>
          </div>
          <div class="stack--inner-item bottom left-bottom">
            <div class="stack--list pd-rt p-t-0 align-self-start">
              <div>
                <h3 class="stack--list-title" v-html="$t('work_approach_page.tech_stack_section.tech_stack_list[4].title')"></h3>
              </div>

              <div class="stack--item-description">{{$t('work_approach_page.tech_stack_section.tech_stack_list[4].text')}}
              </div>
              <div class="stack--list-items">
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/Webflow_Icon.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">Webflow</div>
                </div>
                <div class="stack--list-item">
                  <div class="stack--list-item-icon">
                    <img src="../assets/wordpress.svg" alt="" loading="lazy">
                  </div>
                  <div class="stack--list-item-name">WordPress</div>
                </div>
              </div>
            </div>
            <div class="stack--list pd-lt right-bottom border-bottom-none">
            </div>
          </div>
        </div>
      </div>
    </div>

    <RecentProjects />

    <div class="workflow">
      <div class="container">
        <div>
          <h2 class="workflow-title">{{$t('work_approach_page.workflow_section.title')}}</h2>
        </div>
        <div class="workflow-description">{{$t('work_approach_page.workflow_section.text')}}</div>
        <div class="workflow-scheme">
          <picture>
            <source srcset="../assets/workflow_full.webp"
                    media="(min-width: 768px)"
                    type="image/webp"
            >
            <source srcset="../assets/workflow.webp"
                    type="image/webp"
            >
            <img
                src="../assets/workflow.png"
                alt="Workflow scheme"
                loading="lazy"
            />
          </picture>
        </div>
      </div>
    </div>
    <Contact />
</template>

<script>
import Contact from "../components/Contact_1";
import RecentProjects from "../components/RecentProjects";

import {computed, reactive} from "vue";
import {useHead} from "@vueuse/head";

export default {
  name: "approachWork",
  components: {
    RecentProjects,
    Contact
  },
  setup() {
    const siteData = reactive({
      title: "Work approach in Webmil: Scrum, Kanban, Waterfall",
      description: "We follow agile principles with the option to use a waterfall project management model in demand. Contact us for more details: info@webmil.eu,",
      keywords: "how we work, webmil, work approach",
      robots: "index"
    })

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description)
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords)
        },
        {
          name: `robots`,
          content: computed(() => siteData.robots)
        },
      ],
    })
  },

  data() {
    return {
    }
  }
}
</script>

<style lang="scss">

@import "../base/styles/media";

.border-bottom-none:after{
  content: '';
  width: 0 !important;
}
.work__title {
  padding-top: 280px;
  padding-left: 105px;

  @media(max-width: 991px){
    padding-top: 225px;
    padding-left: 80px;
  }

  @media(max-width: 575px){
    padding-top: 186px;
    padding-left: 50px;
  }

  &-main{
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: -56px;
      left: -68px;
      width: 250px;
      height: 260px;
      z-index: -4;

      @supports (backdrop-filter: blur(40px)) {
        & {
          backdrop-filter: blur(40px);
          -webkit-filter: blur(40px);
          -moz-filter: blur(40px);
          -o-filter: blur(40px);
          -ms-filter: blur(40px);
          filter: blur(40px);

          @media(max-width: 575px){
            backdrop-filter: blur(10px);
          }
        }
      }

      @supports not (backdrop-filter: blur(40px)) {
        & {
          background-image: url("../assets/work_approach_blur.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      @media(max-width: 991px){
        top: -36px;
        left: -43px;
      }

      @media(max-width: 575px){
        width: 130px;
        height: 130px;
        top: -20px;
        left: -23px;
      }
    }

    &:after{
      content: '';
      display: block;
      position: absolute;
      top: -113px;
      left: -105px;
      width: 200px;
      height: 200px;
      background-image: url("../assets/work_approach_circle.png");
      background-size: contain;
      background-repeat: no-repeat;
      z-index: -5;

      @media(max-width: 991px){
        top: -93px;
        left: -80px;
      }

      @media(max-width: 575px){
        top: -50px;
        left: -50px;
        width: 102px;
        height: 102px;
      }
    }
    h1{
      font-weight: 700;
      font-size: 72px;
      line-height: 72px;
      color: #2E3139;
      margin-bottom: 30px;

      @media(max-width: 991px){
        font-size: 54px;
        line-height: 54px;
      }

      @media(max-width: 575px){
        font-size: 36px;
        line-height: 48px;
      }
    }
  }

  &-text {
    max-width: 710px;
  }

  &-logo{
    flex: 0 1 auto;
    padding-left: 100px;

    img{
      width: 100%;
    }

    @media(max-width: 1399px){
      padding-left: 50px;
    }

    @media(max-width: 1199px){
      padding-left: 0px;
    }
  }
}

.delivery {
  margin-top: 137px;
  padding: 73px 0px 0px 0px;
  position: relative;
  z-index: 1;

  &__bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -3;
  }


  @media(max-width: 992px){
    margin-top: 100px;
  }

  @media(max-width: 575px){
    margin-top: 50px;
  }

  @media(max-width: 490px){
    margin-top: 36px;
  }

  &--list{
    max-width: 1239px;
    position: relative;
    z-index: 1;
    padding: 47px 100px 53px 0px;

    @media(min-width: 992px){
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding-right: 60px;
    }

    @media(max-width: 575px){
      padding-right: 30px;
    }



  }

  &--blur{
    display: block;
    width: 1543px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    img{
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @supports (backdrop-filter: blur(20px)) {
      & {
        backdrop-filter: blur(20px);
        -webkit-filter: blur(20px);
        -moz-filter: blur(20px);
        -o-filter: blur(20px);
        -ms-filter: blur(20px);
        filter: blur(20px);
      }
    }

    @supports not (backdrop-filter: blur(20px)) {
      & img{
        display: block;
        //background-image: url("../assets/workapp-bg-blur.png");
        //background-repeat: no-repeat;
        //background-size: cover;
      }
    }

    z-index: -1;

    @media(max-width: 767px){
      right: -12px;
      background-position: 600px;
    }


    @media(max-width: 575px){
      right: -12px;
      background-position: 800px;
    }
  }

  &--title {
    @extend %secondary-title;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
    margin-bottom: -10px;
    position: relative;
    z-index: 2;

  }
  &--item {
    max-width: 507px;
    color: white;

    &:not(:first-child){
      margin-top: 50px;
    }

    @media(max-width: 991px){
      max-width: 580px;
    }
  }

  &--list-images{
    display: flex;

    img:not(:last-child){
      margin-bottom: 34px;
    }

    @media(min-width: 992px){
      flex-direction: column;
      align-items: flex-start;
    }

    @media(max-width: 991px){
      margin-bottom: 43px;
      img:not(:last-child){
        margin-bottom: 0px;
        margin-right: 30px;
      }
    }
  }

  &--item-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  &--item-text {
    font-size: 14px;
    line-height: 22px;
  }
}

.stack{
  position: relative;
  overflow: hidden;
}

@media(min-width: 992px){
  .stack:before{
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color:  #F2F2F2;
    position: absolute;
    top: 206px;
    left: 0;
  }
}


.stack--title {
  @extend %secondary-title;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 99px;

  @media(max-width: 575px){
    margin-top: 36px;
  }
}



.stack--list {
  flex: 0 1 50%;
  display: flex;
  //grid-template-columns: 1fr;
  flex-direction: column;
  padding-bottom: 56px;
  &-items{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));

    @media(max-width: 991px){
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    @media(max-width: 575px){
      max-width: 400px;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 20px;
    }
  }

  .first-item{
    grid-template-columns: repeat(2, 1fr);
  }


  .last-item{
    grid-template-columns: 1fr 155px 1fr;

    @media(max-width: 1399px){
      grid-template-columns: 1fr 125px 1fr;
    }

    @media(max-width: 1199px){
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }

    @media(max-width: 991px){
      grid-template-columns: 200px 135px 200px;
    }

    @media(max-width: 767px){
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media(min-width: 992px){
    padding-top: 48px;
  }

  @media(max-width: 575px){
    &:not(:first-child){
      padding-bottom: 42px;
    }
  }

  &-title {
    font-size: 24px;
    line-height: 36px;
    color: #2E3139;;
    font-weight: bold;
    margin-bottom: 30px;

    @media(max-width: 575px){
      margin-bottom: 12px;
    }
  }

  &-item{
    display: flex;
    align-items: center;
    min-height: 48px;

    &-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 25px;
      margin-right: 16px;
    }
  }

  &-item-name{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #2E3139;

    @media(max-width: 575px){
      font-size: 14px;
      line-height: 24px;
    }
  }
}


@media(min-width: 992px){
  .top{
    .stack--list:nth-child(2){
      position: relative;
      flex: 1 1 35%;
    }
    .stack--list:nth-child(2):after{
      content: '';
      display: block;
      width: calc(100% + 368px);
      height: 1px;
      background-color:  #F2F2F2;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .stack--list:nth-child(2){
      position: relative;
    }


    .stack--list:nth-child(2):before{
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      background-color:  #F2F2F2;
      position: absolute;
      left: 0;
      top: 0;
    }

    .stack--list:first-child{
      position: relative;
      flex: 0 1 45%;

      @media(max-width: 1199px){
        padding-right: 80px;
      }
    }

    .stack--list:first-child:before{
      content: '';
      display: block;
      width: calc(100% + 304px);
      height: 1px;
      background-color:  #F2F2F2;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .bottom {

    .stack--list:first-child {
      position: relative;
      flex: 0 1 45%;

      @media(max-width: 1199px){
        padding-right: 80px;
      }
    }


    .stack--list:first-child:before {
      content: '';
      display: block;
      width: calc(100% + 304px);
      height: 1px;
      background-color:  #F2F2F2;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .stack--list:nth-child(2){
      position: relative;
      flex: 1 1 45%;
    }

    .stack--list:nth-child(2):before{
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      background-color:  #F2F2F2;
      position: absolute;
      left: 0;
      top: 0;
    }

    .stack--list:nth-child(2):after{
      content: '';
      display: block;
      width: calc(100% + 368px);;
      height: 1px;
      background-color:  #F2F2F2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}


.p-t-0{
  padding-top: 0;
}

.pd-rt{
  margin-right: 0px;
}

@media(min-width: 992px){
  .pd-lt{
    padding-left: 104px;
  }
}



.stack--inner{
  margin-top: 42px;
}

.stack--inner-item{
  @media(min-width: 992px){
    display: flex;
  }
}


.stack--item-description {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 24px;
  @media(min-width: 992px){
    max-width: 419px;
  }

  @media(max-width: 575px){
    order: 3;
    margin-top: 24px;
    margin-bottom: 0px;
  }
}

@media(max-width: 767px){

  .left-bottom{
    .stack--list-item:nth-child(1){
      order: 1;
    }

    .stack--list-item:nth-child(2){
      order: 3;
    }

    .stack--list-item:nth-child(3){
      order: 4;
    }

    .stack--list-item:nth-child(4){
      order: 5;
    }

    .stack--list-item:nth-child(5){
      order: 2;
    }
  }

  .right-bottom{
    .stack--list-item:nth-child(1){
      order: 6;
    }

    .stack--list-item:nth-child(2){
      order: 2;
    }

    .stack--list-item:nth-child(3){
      order: 1;
    }

    .stack--list-item:nth-child(4){
      order: 5;
    }

    .stack--list-item:nth-child(5){
      order: 4;
    }

    .stack--list-item:nth-child(6){
      order: 3;
    }
  }

  .right-top{
    .stack--list-item:nth-child(1){
      order: 1;
    }

    .stack--list-item:nth-child(2){
      order: 7;
    }

    .stack--list-item:nth-child(3){
      order: 8;
    }

    .stack--list-item:nth-child(4){
      order: 3;
    }

    .stack--list-item:nth-child(5){
      order: 2;
    }

    .stack--list-item:nth-child(6){
      order: 6;
    }

    .stack--list-item:nth-child(7){
      order: 5;
    }

    .stack--list-item:nth-child(8){
      order: 4;
    }
  }
}





a {
  color: black;
}


.workflow {
  margin-top: 260px;
  position: relative;

  @media(max-width: 767px){
    margin-top: 120px;
  }

  @media(max-width: 575px){
    margin-top: 80px;
  }
  &-title {
    @extend %secondary-title;
    font-weight: bold;
    letter-spacing: 2px;
    color: #2E3139;
    margin-bottom: 30px;
    @media(max-width: 767px){
      text-align: center;
    }
  }
  &-description {
    font-size: 20px;
    line-height: 28px;
    color: #2E3139;
    max-width: 800px;
    margin-bottom: 80px;

    @media(max-width: 767px){
      display: none;
    }
  }

  img{
    max-width: 100%;
  }

  @media(max-width: 767px){
    &-scheme{
      display: flex;
      justify-content: center;
    }
  }
}
</style>
